import { MODULE_CONFIG } from '$lib/js/module_config';

export const MODULES_DICT = [
	{ en: 'General', 'zh-tw': '一般' },
	{ en: '3PL', 'zh-tw': '第三方物流' },
	{ en: 'Customs', 'zh-tw': '海關' },
	{ en: 'Support', 'zh-tw': '支援' },
	{ en: 'Streamer', 'zh-tw': '主播' },
	{ en: 'Admin', 'zh-tw': '管理員' },
	{ en: 'Legacy', 'zh-tw': '舊版' },
	{ en: 'Search modules', 'zh-tw': '搜尋模組' },
	{ en: 'Beta', 'zh-tw': '測試版' },
	{ en: 'Legacy', 'zh-tw': '舊版' },
	{ en: 'Coming Soon', 'zh-tw': '即將推出' },
	{ en: 'New', 'zh-tw': '新' },
];

export const MODULES = [
	{
		name: 'General',
		title: 'General',
		pages: [
			{
				path: '/finance/expensify',
				title: '👛  Log Expense',
				emoji: '👛',
				en: 'Log Expense',
				'zh-tw': '記錄花費',
				roles: MODULE_CONFIG['log-expense']?.roles,
			},
			{
				path: '/fulfillment/gift-cards',
				title: '💳 Gift Cards',
				emoji: '💳',
				en: 'Gift Cards',
				'zh-tw': '禮品卡',
				roles: MODULE_CONFIG['gift-cards']?.roles,
			},
			{
				path: 'https://once.deputy.com/my/login',
				title: 'Deputy',
				en: 'Deputy',
				icon: 'https://ghhmcjouhiptfkuvobgy.supabase.co/storage/v1/object/public/public/assets/deputy_logo.jpeg',
				target: '_blank',
				roles: ['Basic'],
			},
			{
				path: 'https://www.notion.so/wanver/Wiki-ab25319a595c44f995b5bd39d88465ea',
				title: 'Wiki',
				en: 'Wiki',
				icon: 'https://ghhmcjouhiptfkuvobgy.supabase.co/storage/v1/object/public/public/assets/notion_logo.png',
				target: '_blank',
				roles: ['Basic'],
			},
			{
				path: '/products/classify',
				title: 'Classify Products',
				emoji: '👕',
				en: 'Classify Products',
				'zh-tw': '產品分類',
				roles: MODULE_CONFIG['classify-products']?.roles,
			},
			{
				path: '/finance/log-balance',
				title: '🏦 Log Balance',
				emoji: '🏦',
				en: 'Log Balance',
				'zh-tw': '記錄餘額',
				roles: MODULE_CONFIG['log-balance']?.roles,
			},
		],
	},
	{
		name: '3PL',
		title: '3PL',
		pages: [
			{
				path: '/pick-order/menu',
				title: '👷🏻‍♂️ Picker',
				emoji: '👷🏻‍♂️',
				en: 'Picker',
				'zh-tw': '撿貨員',
				roles: MODULE_CONFIG['pick-order']?.roles,
			},
			{
				path: '/pack-order/v2/find-order',
				title: '🧑🏻‍🔧🇹🇼 Packer V2',
				emoji: '🧑🏻‍🔧🇹🇼',
				en: 'Packer V2',
				'zh-tw': '打包員 V2',
				roles: MODULE_CONFIG['pack-order-v2']?.roles,
			},
			// {
			// 	path: '/inventory/binning/v2/scan-item',
			// 	title: '🗑🇹🇼 Bin',
			// 	emoji: '🗑🇹🇼',
			// 	en: 'Bin V2',
			// 	'zh-tw': '貨品入庫V2',
			// 	roles: MODULE_CONFIG['bin-items-v2']?.roles,
			// },
			{
				path: '/inventory/binning/v3/scan-item',
				title: '🗑🇹🇼 Bin V3',
				emoji: '🗑🇹🇼',
				en: 'Bin V3',
				'zh-tw': '貨品入庫V3',
				roles: MODULE_CONFIG['bin-items-v2']?.roles,
				tags: ['New', 'Beta'],
			},
			{
				path: '/pack-box/v2-items/find-box',
				title: '📦👕  Pack Box V2',
				emoji: '📦👕',
				en: 'Pack Box V2',
				'zh-tw': '打包箱子 V2',
				roles: MODULE_CONFIG['pack-box-v2']?.roles,
			},
			{
				path: '/customs/customs-forms',
				title: '⛴  Prepare Shipment',
				emoji: '⛴',
				en: 'Prepare Shipment',
				'zh-tw': '準備出口',
				roles: MODULE_CONFIG['shipments']?.roles,
			},
			{
				path: '/fulfillment/requests',
				title: '🛍️ Fulfillment',
				emoji: '🛍️',
				en: 'Fulfillment',
				'zh-tw': '履行訂單',
				roles: MODULE_CONFIG['fulfillment-requests']?.roles,
			},
			// {
			// 	path: '/fulfillment/receiving',
			// 	title: '🚪 Receiving',
			// 	emoji: '🚪',
			// 	en: 'Receiving',
			// 	'zh-tw': '批貨點貨確認',
			// 	roles: MODULE_CONFIG['receiving']?.roles,
			// },
			{
				path: '/inventory/binning/bin/RiverRoadHeldSamples?from=modules',
				title: '🛑 👕 Held Samples',
				emoji: '🛑 👕',
				en: 'Held Samples',
				'zh-tw': '保留樣品',
				roles: MODULE_CONFIG['held-samples']?.roles,
			},
			{
				path: '/inventory/binning/bin/RiverRoadsamplesnoSale?from=modules',
				title: '🛑 💄 Samples No Sale',
				emoji: '🛑 💄',
				en: 'Samples No Sale',
				'zh-tw': '樣品不賣',
				roles: MODULE_CONFIG['held-samples']?.roles,
			},
			{
				path: '/inventory/binning/clear-bin',
				title: '❌ 🗑  Clear Bin',
				emoji: '❌ 🗑',
				en: 'Clear Bin',
				'zh-tw': '清空儲位',
				roles: MODULE_CONFIG['clear-bin']?.roles,
			},
			{
				path: '/orders/print-label',
				title: '🖨️ Last Mile',
				emoji: '🖨️',
				en: 'Last Mile',
				'zh-tw': '列印出貨便',
				roles: MODULE_CONFIG['last-mile']?.roles,
			},
			{
				path: '/warehouse/taiwan/receiving',
				title: '🚚 Receiving',
				emoji: '🚚',
				en: 'Receiving',
				'zh-tw': '接收物品',
				roles: MODULE_CONFIG['receive-box']?.roles,
			},
			{
				path: '/customs/shipments/inbound',
				title: '⛴ Inbound Shipments',
				emoji: '⛴',
				en: 'Inbound Shipments',
				'zh-tw': '入境箱數總覽',
				roles: MODULE_CONFIG['inbound-shipments']?.roles,
			},
			{
				path: '/3pl/monitor',
				title: '📊 3PL Monitor',
				emoji: '📊',
				en: '3PL Monitor',
				'zh-tw': '3PL 總覽',
				roles: MODULE_CONFIG['3pl-monitor']?.roles,
			},

			{
				path: '/fulfillment/tracking',
				title: '🧾 Tracking',
				emoji: '🧾',
				en: 'Tracking',
				'zh-tw': '追蹤',
				roles: MODULE_CONFIG['tracking']?.roles,
			},
		],
	},
	{
		name: 'Customs',
		title: 'Customs',
		pages: [
			{
				path: '/customs/taiwan-customs',
				title: '🇹🇼 Taiwan Customs',
				emoji: '🇹🇼',
				en: 'Taiwan Customs',
				'zh-tw': '台灣海關',
				roles: MODULE_CONFIG['taiwan-customs']?.roles,
			},
			{
				path: '/customs/taiwan-customs/payments',
				title: '🇹🇼 Customs Payments',
				emoji: '🇹🇼',
				en: 'Customs Payments',
				'zh-tw': '台灣海關稅',
				roles: MODULE_CONFIG['customs-payments']?.roles,
			},
		],
	},
	{
		name: 'Support',
		title: 'Support',
		pages: [
			{
				path: '/search/search-item',
				title: '🔍  Find Item',
				emoji: '🔍',
				en: 'Find Item',
				'zh-tw': '查找商品',
				roles: MODULE_CONFIG['find-item']?.roles,
			},
			{
				path: '/search/search-order',
				title: '🔍  Find Order',
				emoji: '🔍',
				en: 'Find Order',
				'zh-tw': '查找訂單',
				roles: MODULE_CONFIG['find-order']?.roles,
			},
			{
				path: '/search/search-box',
				title: '🔍  Find Box',
				emoji: '🔍',
				en: 'Find Box',
				'zh-tw': '查找箱子',
				roles: MODULE_CONFIG['find-box']?.roles,
			},
			{
				path: '/support',
				title: '👩‍💻 Support',
				emoji: '👩‍💻',
				en: 'Support',
				'zh-tw': '客服',
				roles: MODULE_CONFIG['support']?.roles,
			},
			{
				path: '/livestream/products/timestamps',
				title: '⌚ Announcement Times',
				emoji: '⌚',
				en: 'Announcement Times',
				'zh-tw': '公告时间',
				roles: MODULE_CONFIG['announcement-timestamps']?.roles,
			},
			{
				path: '/livestream/tools/video-upload',
				title: '📼 Stream Upload',
				emoji: '📼',
				en: 'Stream Upload',
				'zh-tw': '串流影片上傳',
				roles: MODULE_CONFIG['stream-video-upload']?.roles,
			},
			{
				path: '/admin/shopline-actions',
				title: '🛒 Shopline Actions',
				emoji: '🛒',
				en: 'Shopline Actions',
				'zh-tw': 'SHOPLINE 動作',
				roles: MODULE_CONFIG['shopline-actions']?.roles,
			},
		],
	},
	{
		name: 'Streamer',
		title: 'Streamer',
		pages: [
			{
				path: '/livestream/livestream-url',
				title: '🎥  Livestream',
				emoji: '🎥',
				en: 'Livestream',
				'zh-tw': '直播建單',
				roles: MODULE_CONFIG['listing']?.roles,
			},
			{
				path: '/buyer',
				title: '🅱️ Buyer',
				emoji: '️🅱️',
				en: 'Buyer',
				'zh-tw': '買方',
				roles: MODULE_CONFIG['buyer']?.roles,
			},
			{
				path: '/inventory/binning/bin/GSHeldSamples?from=modules&sourceMode=transfer',
				title: '📥👕 Samples',
				emoji: '📥👕',
				en: 'Samples',
				'zh-tw': '樣品',
				roles: MODULE_CONFIG['gs-held-samples']?.roles,
			},
			{
				path: '/fulfillment/add-inventory/all/select-streamer',
				title: '✅ Add Quantity',
				emoji: '✅',
				en: 'Add Quantity',
				'zh-tw': '添加庫存',
				roles: MODULE_CONFIG['add-inventory']?.roles,
			},
			{
				path: '/notifications/push',
				title: '💬  LINE Push',
				emoji: '💬',
				en: 'LINE Push',
				'zh-tw': 'LINE 推播',
				roles: MODULE_CONFIG['line-push']?.roles,
			},
			{
				path: '/captions/language',
				title: '🌵  Livestream Captions',
				emoji: '🌵',
				en: 'Livestream Captions',
				'zh-tw': '直播即時字幕',
				roles: MODULE_CONFIG['livestream-captions']?.roles,
			},
			{
				path: '/livestream/companion',
				title: '🎥  Stream Companion',
				emoji: '🎥',
				en: 'Stream Companion',
				'zh-tw': '跟播字幕',
				roles: MODULE_CONFIG['stream-companion']?.roles,
				// tags: ['Beta'],
			},
			{
				path: '/inventory/samples/user',
				title: '👕  My Samples',
				emoji: '👕 ',
				en: 'My Samples',
				'zh-tw': '我的樣品',
				roles: MODULE_CONFIG['user-samples']?.roles,
				tags: ['Beta'],
			},
			// {
			// 	path: '/livestream/listings',
			// 	title: '🎥👕  Stream Listings',
			// 	emoji: '🎥👕 ',
			// 	en: 'Stream Listings',
			// 	'zh-tw': '串流產品列表',
			// 	roles: MODULE_CONFIG['stream-listings']?.roles,
			// 	tags: ['Beta'],
			// },
			// {
			// 	path: '/modules',
			// 	title: '📊  Dashboard',
			// 	emoji: '📊',
			// 	en: 'Dashboard',
			// 	'zh-tw': '總覽',
			// 	roles: MODULE_CONFIG['streamer-dashboard']?.roles,
			// 	tags: ['Coming Soon'],
			// },
		],
	},
	{
		name: 'Admin',
		title: 'Admin',
		pages: [
			{
				path: '/reporting/dashboards/executive',
				title: '📊 Dashboard',
				emoji: '📊',
				en: 'Dashboard',
				'zh-tw': '儀表板',
				roles: MODULE_CONFIG['executive-dashboard']?.roles,
				tags: ['Beta'],
			},
			{
				path: '/refulfillment/view',
				title: '👕  Refulfill',
				emoji: '👕',
				en: 'Refulfill',
				'zh-tw': '補貨',
				roles: MODULE_CONFIG['refulfill']?.roles,
			},
			{
				path: '/xml/menu',
				title: '💾  Products XML',
				emoji: '💾',
				en: 'Products XML',
				'zh-tw': '产品 XML',
				roles: MODULE_CONFIG['product-xml']?.roles,
			},
			{
				path: '/samples/select-brand',
				title: '🔗  Order Samples',
				emoji: '🔗',
				en: 'Order Samples',
				'zh-tw': '訂購樣品',
				roles: MODULE_CONFIG['order-samples']?.roles,
			},
			{
				path: '/fulfillment/gift-cards/search',
				title: '💳 Manage Gift Cards',
				emoji: '💳',
				en: 'Manage Gift Cards',
				'zh-tw': '管理禮品卡',
				roles: MODULE_CONFIG['gift-cards-management']?.roles,
			},
			{
				path: '/inventory/binning/find-bin',
				title: '🗑  Edit Bin',
				emoji: '🗑',
				en: 'Edit Bin',
				'zh-tw': '編輯儲位',
				roles: MODULE_CONFIG['edit-bin']?.roles,
			},
			{
				path: '/fulfillment/menu',
				title: '🛍  Grocery List',
				emoji: '🛍',
				en: 'Grocery List',
				'zh-tw': '食品清單',
				roles: MODULE_CONFIG['grocery-list']?.roles,
			},
			{
				path: '/inventory/manage',
				title: '📊  Inventory Management',
				emoji: '📊',
				en: 'Inventory Management',
				'zh-tw': '庫存報告',
				roles: MODULE_CONFIG['inventory-management']?.roles,
			},
			{
				path: '/tools/syncing-tools',
				title: '🛠 Syncing Tools',
				emoji: '🛠',
				en: 'Syncing Tools',
				'zh-tw': '同步工具',
				roles: MODULE_CONFIG['syncing-tools']?.roles,
			},
			{
				path: '/admin/operations-dashboard',
				title: '🧑‍🤝‍🧑 Operations Dashboard',
				emoji: '🧑‍🤝‍🧑',
				en: 'Operations Dashboard',
				'zh-tw': '運營儀表板',
				roles: MODULE_CONFIG['operations-dashboard']?.roles,
			},
			{
				path: '/reporting/kpis',
				title: '📊  KPIs',
				emoji: '📊',
				en: 'KPIs',
				'zh-tw': 'KPIs',
				roles: MODULE_CONFIG['kpis']?.roles,
			},
			{
				path: '/accounting',
				title: '📊  Accounting',
				emoji: '📊',
				en: 'Accounting',
				'zh-tw': '會計',
				roles: MODULE_CONFIG['accounting']?.roles,
			},
			{
				path: '/admin/config/users',
				title: '🧑‍⚕️ Manage Users',
				emoji: '🧑‍⚕️',
				en: 'Manage Users',
				'zh-tw': '管理用戶',
				roles: MODULE_CONFIG['manage-users']?.roles,
			},
			{
				path: '/admin/manage-brands',
				title: '🏘️ Manage Brands & Merchants',
				emoji: '🏘️',
				en: 'Manage Brands & Merchants',
				'zh-tw': '管理品牌和商家',
				roles: MODULE_CONFIG['manage-brands']?.roles,
			},
			{
				path: '/admin/config/manage-colors',
				title: '🎨 Add / Manage Colors',
				emoji: '🎨',
				en: 'Add / Manage Colors',
				'zh-tw': '添加/管理顏色',
				roles: MODULE_CONFIG['manage-colors']?.roles,
			},
			{
				path: '/admin/config/manage-sizes',
				title: '👕 Add / Manage Sizes',
				emoji: '👕',
				en: 'Add / Manage Sizes',
				'zh-tw': '添加/管理尺寸',
				roles: MODULE_CONFIG['manage-sizes']?.roles,
			},
			{
				path: '/inventory/samples/user/all',
				title: '👕 Manage User Samples',
				emoji: '👕',
				en: 'Manage User Samples',
				'zh-tw': '管理用戶樣品',
				roles: MODULE_CONFIG['manage-user-samples']?.roles,
			},
			{
				path: '/tools/caption-config',
				title: '💬 Manage Caption Styles',
				emoji: '💬',
				en: 'Manage Caption Styles',
				'zh-tw': '管理字幕樣式',
				roles: MODULE_CONFIG['manage-caption-styles']?.roles,
			},
			{
				path: '/admin/tools',
				title: '🛠️ Admin Tools',
				emoji: '🛠️',
				en: 'Admin Tools',
				'zh-tw': '管理工具',
				roles: MODULE_CONFIG['admin-tools']?.roles,
			},
			{
				path: '/admin/dev',
				title: '🧑‍💻 Developer',
				emoji: '🧑‍💻',
				en: 'Developer',
				'zh-tw': '開發者',
				roles: MODULE_CONFIG['dev']?.roles,
			},
		],
	},
	{
		name: 'Legacy',
		title: 'Legacy',
		pages: [],
	},
];
